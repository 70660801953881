.CentralBackendPartnerReport {
  box-sizing: border-box;
  margin-top: 32px;
  position: relative;
}

.CentralBackendPartnerReport h1 {
  font-size: 32px;
}

.CentralBackendPartnerReport h2 {
  font-size: 28px;
}

.StoreSection {
  background-color: white;
  margin-top: 30px;
  padding: 25px 15px;
}

.StoreDetails {
  margin-top: 20px;
}

.StoreDetails:first-of-type {
  margin-top: 0;
}

.LabelValueWrapper {
  display: flex;
}

.LabelValueWrapper > p {
  display: inline-block;
  margin: 0;
}

.LabelValueWrapper > p:first-of-type {
  margin-right: 10px;
  font-weight: bold;
}

.StoreSection .LabelValueWrapper {
  font-size: 14px;
}

.StoreNonprofits {
  font-size: 14px;
}

.StoreNonprofits > p {
  font-weight: bold;
  margin: 0;
}

.StoreNonprofits > ul {
  list-style: none;
  padding-left: 25px;
  margin: 0;
}

.StoreNonprofits > ul > li {
  margin-top: 5px;
}
