.container {
  width: 100%;
}
.select-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}
form {
  width: 100%;
}
.error {
  color: red;
  margin: 10px;
}
.button-container {
  margin-top: 25px;
}
.key-display {
  margin-top: 20px;
  text-align: center;
  border: none;
  cursor: copy;
}
.copy-to-clipboard {
  color: #b8cfa3;
}
