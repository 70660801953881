.top-level-container {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
}
.filter-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 15px;
  box-sizing: border-box;
}
.scroll-container {
  overflow: auto;
  height: 600px;
}
.pagination-scroll-container {
  overflow: auto;
}
.table-container {
  min-width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(227 230 236) 10px 5px 32px;
  padding: 15px 15px 0px 15px;
  box-sizing: border-box;
  width: fit-content;
}
.headers-container {
  display: flex;
  color: rgb(178, 179, 189);
  font-size: 13px;
  border-bottom: 1px solid rgb(228, 228, 228);
  padding-top: 23px;
  padding-bottom: 23px;
  font-weight: 500;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  background-color: rgb(255, 255, 255);
  max-width: 100%;
  z-index: 15;
}
.header {
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  width: 200px;
  background-color: rgb(255, 255, 255);
}
.frozen-column {
  position: sticky;
  left: 0;
  z-index: 10;
}
.row {
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
}
.empty-table {
  padding-top: 15px;
  border-bottom: none;
}
.cell {
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-size: 0.9em;
  width: 200px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  padding: 20px;
}
.expander-cell {
  width: 100px;
  background-color: rgb(255, 255, 255);
}
.expandable-row {
  height: 0px;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.5s;
  transition-delay: 0.08s;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  padding: 15px 0px 0px 15px;
}
.pagination-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 50px;
  margin-top: 30px;
}
.pagination-stats-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.pagination-buttons-container {
  display: flex;
}
.simple-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 168px;
}
