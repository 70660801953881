.container {
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  background-color: whitesmoke;
  padding: 10px;
}
.large-container {
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 85vh;
  width: 90vw;
  background-color: whitesmoke;
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  color: red;
  margin: 10px;
}

.select-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

/* close button */
.modal-close-button-container {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.modal-close-button {
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  text-indent: 100%;
}
.modal-close-button:hover {
  opacity: 1;
}
.modal-close-button:before,
.modal-close-button:after {
  position: absolute;
  left: 9px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333;
}
.modal-close-button:before {
  transform: rotate(45deg);
}
.modal-close-button:after {
  transform: rotate(-45deg);
}
