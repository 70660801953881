@media only screen and (max-width: 992px) {
  #side-nav {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  #top-nav {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-list a > div {
    margin-left: 2px !important;
    font-size: 12px !important;
  }
}

#top-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 20;
}
.nav-page-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #b2b3bd;
  margin: 30px 0px 14px 20px;
}
.nav-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.nav-list a > div {
  margin-left: 10px;
}
.nav-list > a {
  width: 80%;
  height: 46px;
  display: flex;
  padding: 17px 44px 18px 22px;
  align-items: center;
  color: #808191;
  text-decoration: none;
  line-height: 1.4;
  font-weight: 550;
  border-radius: 5px;
  padding: 5px 10px;
}
.nav-list > a:hover {
  text-decoration: none;
  color: #ffffff;
  background-image: linear-gradient(270deg, #fbab7e, #f7ce68);
}
.nav-list > a:active {
  text-decoration: none;
  color: #ffffff;
  background-image: linear-gradient(270deg, #fbab7e, #f7ce68);
}
.nav-list.active {
  background-image: linear-gradient(270deg, #fbab7e, #f7ce68);
  color: #ffffff !important;
}

#burger-menu {
  cursor: pointer;
}
.MuiIconButton-root {
  padding: 0px 0px 0px 16px !important;
}

#top-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 5px 0px 5px;
  height: 50px;
}

#nav-drawer,
#nav-drawer-header {
  width: 200px;
}

@media only screen and (max-width: 960px) {
  #nav-drawer,
  #nav-drawer-header {
    width: 300px;
  }

  #nav-drawer #large-screen-side-nav {
    padding: 0 15px;
  }
}

#nav-drawer-header {
  display: flex;
  justify-content: flex-end;
}
