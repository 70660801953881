:root {
  --beam-color--white: #ffffff;
  --beam-color--black: #000000;

  --beam-color--charcoal-50: #fafafa;
  --beam-color--charcoal-100: #dfe2e0;
  --beam-color--charcoal-200: #b5bab8;
  --beam-color--charcoal-400: #55555c;
  --beam-color--charcoal-500: #626a66;
  --beam-color--charcoal-600: #454a47;
  --beam-color--charcoal-800: #171918;
  --beam-color--charcoal: var(--beam-color--charcoal-500);

  --beam-color--grey: #55555c;
  --beam-color--light-grey: #eceff5;
  --beam-color--light-grey-1: #8e8e8e;

  --beam-color--sun-50: #fffbeb;
  --beam-color--sun-100: #fff4c2;
  --beam-color--sun-200: #ffe570;
  --beam-color--sun-500: #ffd522;
  --beam-color--sun-600: #f5c800;
  --beam-color--sun-800: #8f7500;
  --beam-color--sun: var(--beam-color--sun-500);

  --beam-color--coral-50: #fff3eb;
  --beam-color--coral-100: #ffdcc2;
  --beam-color--coral-200: #ffae70;
  --beam-color--coral-500: #ff7e1e;
  --beam-color--coral-600: #cc5800;
  --beam-color--coral-800: #8f3e00;
  --beam-color--coral: var(--beam-color--coral-500);

  --beam-color--cherry-50: #ffedeb;
  --beam-color--cherry-100: #ffc8c2;
  --beam-color--cherry-200: #fe9185;
  --beam-color--cherry-500: #fe4633;
  --beam-color--cherry-600: #df1701;
  --beam-color--cherry-800: #a21101;
  --beam-color--cherry: var(--beam-color--cherry-500);
  --beam-color--error: var(--beam-color--cherry-600);

  --beam-color--sky-50: #ebf3ff;
  --beam-color--sky-100: #c2dcff;
  --beam-color--sky-200: #5ca3ff;
  --beam-color--sky-500: #006eff;
  --beam-color--sky-600: #0058cc;
  --beam-color--sky-800: #00357a;
  --beam-color--sky: var(--beam-color--sky-500);

  --beam-color--water-50: #ebf5ff;
  --beam-color--water-100: #c2e2ff;
  --beam-color--water-200: #70bcff;
  --beam-color--water-500: #49abff;
  --beam-color--water-600: #006dcc;
  --beam-color--water-800: #004c8f;
  --beam-color--water: var(--beam-color--water-500);

  --beam-color--navy: #00263a;

  --beam-color--moss-50: #ebfffd;
  --beam-color--moss-100: #99fff5;
  --beam-color--moss-200: #00f5dc;
  --beam-color--moss-500: #00c8b5;
  --beam-color--moss-600: #00a393;
  --beam-color--moss-800: #00665c;
  --beam-color--moss: var(---beam-color--moss-500);

  --beam-color--lime-50: #f9feeb;
  --beam-color--lime-100: #eefdc4;
  --beam-color--lime-200: #dcfa89;
  --beam-color--lime-500: #cff85e;
  --beam-color--lime-600: #b9f514;
  --beam-color--lime-800: #92c408;
  --beam-color--lime: var(--beam-color--lime-500);
}

.beam-bg--gradient-core {
  background: radial-gradient(
    167.61% 436.52% at -5.99% 125.52%,
    var(--beam-color--sky) 0%,
    #c5be54 31.77%,
    var(--beam-color--sun) 53.65%,
    var(--beam-color--cherry) 100%
  );
}

.beam-bg--gradient-coral {
  background: linear-gradient(
    75.68deg,
    var(--beam-color--water) -75.52%,
    var(--beam-color--sun) 14.23%,
    var(--beam-color--cherry) 91.79%
  );
}
