@font-face {
  font-family: 'Apfel Grotezk';
  src: url('https://cdn01.beamimpact.com/fonts/ApfelGrotezk-Regular.woff2') format('woff2'),
    url('https://cdn01.beamimpact.com/fonts/ApfelGrotezk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Mabry';
  font-weight: 400;
  src: url('https://cdn01.beamimpact.com/fonts/mabry-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Mabry';
  font-weight: 500;
  src: url('https://cdn01.beamimpact.com/fonts/mabry-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Mabry';
  font-weight: 700;
  src: url('https://cdn01.beamimpact.com/fonts/mabry-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Mabry';
  font-weight: 900;
  src: url('https://cdn01.beamimpact.com/fonts/mabry-black.woff2') format('woff2');
}
