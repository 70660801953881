.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-container {
  margin-bottom: 20px;
}
.filter-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.kpis-container {
  padding: 15px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  font-size: 13px;
}
.beam-non-beam-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.kpis-top-line-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0px;
}
.kpis-top-line-filters {
  display: flex;
  flex-wrap: wrap;
}
.kpis-top-line-filters > div:first-child {
  margin-right: 15px;
}
