.container {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-bottom: 8px;
}

.switch-container {
  width: 40px;
  height: 15px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
}
.switch {
  width: 15px;
  height: 100%;
  border-radius: 15px;
}
.label {
  font-size: 12px;
}
