@media only screen and (max-width: 600px) {
  .beam-input {
    padding: 5px 5px !important;
  }
}

.beam-input,
.beam-input-error {
  border-radius: 8px;
  cursor: text;
  background-color: #e4e4e4;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  outline: none;
  border: none;
  padding: 18px 23px;
  word-break: break-all;
  min-height: 21px;
  width: 100%;
  box-sizing: border-box;
}
.beam-input-error {
  background-color: rgb(255, 153, 153);
}
.beam-form-field-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
