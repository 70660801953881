.nonprofit-conatiner {
  display: flex;
  flex-direction: column;
}

#nonprofit-image {
  object-fit: cover;
  width: 80%;
  height: 80%;
  max-width: 8em;
  max-height: 4em;
  min-height: 2em;
  min-width: 4em;
  border-radius: 5px;
}
