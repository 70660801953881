.button {
  background-color: rgb(27, 29, 33);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 180px;
  height: 56px;
  line-height: 1.43;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.button-outlined {
  background-color: rgb(255, 255, 255);
  color: rgb(27, 29, 33);
  border-radius: 8px;
  width: 180px;
  height: 56px;
  line-height: 1.43;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid rgb(27, 29, 33);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.button__gradient {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 600;
  background-color: transparent;
  color: #808191;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 7px 16px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
}

.button__gradient--selected {
  background-image: linear-gradient(270deg, #fbab7e, #f7ce68);
  color: #fff;
}

.disabled,
.button:disabled,
.button-outlined:disabled,
.button__gradient:disabled {
  background-color: grey !important;
  background-image: none;
  cursor: not-allowed;
}
