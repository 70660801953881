.tableContainerBasic,
.tableContainerBarebones {
  overflow: auto;
  display: block; /* Makes the table horizontally scrollable. */
  position: relative;
}

.tableContainerBasic {
  border: 1px solid var(--beam-color--charcoal-100);
  border-radius: var(--beam--border-radius);
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table .thead th,
.table .tfoot th {
  text-align: left;
  white-space: nowrap;
}

.tableContainerBasic .thead {
  background-color: var(--beam-color--sky-50);
}

.tableContainerBarebones .thead {
  background-color: inherit;
  text-align: left;
}

.tableContainerBasic .thead th {
  position: relative;
  padding: var(--beam--table-cell-y-padding) var(--beam--table-cell-x-padding);
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-x-small);
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.tableContainerBarebones .thead th {
  position: relative;
  padding: var(--beam--table-cell-y-padding) var(--beam--table-cell-x-padding);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  text-transform: none;
  color: var(--beam-color--charcoal-800);
}

/* Prevents right-alignment on last header, making all headers are left-aligned.  */
.persistHeaderAlignment .thead th:last-child {
  text-align: left;
}

.tbody {
  background-color: inherit;
}

.tableContainerBasic tbody tr:nth-child(odd) td {
  background-color: var(--beam-color--white);
}

.tableContainerBasic tbody tr:nth-child(even) td {
  background-color: var(--beam-color--charcoal-50);
}

.tbody td {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  line-height: 15px;
  padding: var(--beam--table-cell-x-padding);
}

.condensed {
  padding: 6px var(--beam--table-cell-x-padding) !important;
}

.tfoot th {
  position: relative;
  padding: var(--beam--table-cell-y-padding) var(--beam--table-cell-x-padding);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: var(--beam--font-weight-bold);
  font-size: var(--beam--font-size-small);
  background-color: var(--beam-color--white);
  line-height: 35px;
}

td.noData {
  @apply font-primary font-medium !text-center text-charcoal-500 text-xl py-16;
}

/**
 * START
 * Styles for Sort/Expand
 */

.sortingHeader {
  cursor: pointer;
}

.sort {
  display: inline;
}

.sortIconDeselected {
  opacity: 0;
}

.sortingHeader:hover .sortIconDeselected {
  opacity: 0.33;
}

.expanded,
.collapsed {
  border: 0;
  background-color: transparent;
}

.expanded {
  transform: rotate(180deg) scaleX(-1);
}

/**
 * END
 * Styles for Sort/Expand
 */

/**
 * START
 * Styles for Pagination UI
 */

.pagination {
  display: flex;
  align-items: center;
  margin: 15px 15px;
}

.paginationPageIndicator {
  margin: 0 10px;
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: var(--beam--font-size-small);
  color: var(--beam-color--charcoal-800);
}

.paginationButton {
  margin: 0 4px;
}

.paginationButton::part(label) {
  display: inline-flex;
  align-items: center;
}

/**
 * END
 * Styles for Pagination UI
 */

/**
 * START
 * Styles for Fixed Header / Fixed Columns
 */

.fixedFirstRow {
  overflow-y: auto;
}

.fixedFirstCol {
  overflow-x: auto;
  width: 100%;
  padding-bottom: 0;
}

.fixedFirstRow tbody,
.fixedFirstCol tbody {
  z-index: 0;
}

.fixedFirstRow thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.fixedFirstCol thead th:first-child,
.fixedFirstCol tfoot th:first-child,
.fixedFirstCol tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.fixedFirstCol thead th:first-child {
  top: 0;
  z-index: 2;
  background-color: var(--beam-color--sky-50);
}

/* Adds fade-out effect to edge of fixed column when content scrolls underneath */
.fixedFirstCol thead th:first-child::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 10px;
  background: linear-gradient(90deg, var(--beam-color--sky-50) 0%, transparent 100%);
  content: ' ';
  z-index: 3;
}

/* Adds fade-out effect to right edge of overflowed header (height/scrollbar offset set by JS) */
.headerOverflowOverlay {
  position: absolute;
  top: 0;
  right: 12px; /* estimated initial scrollbar offset */
  width: 30px;
  height: 47px; /* estimated initial header height */
  background: linear-gradient(-90deg, var(--beam-color--sky-50) 20%, transparent 100%);
  content: ' ';
  z-index: 3;
}

/**
 * END
 * Styles for Fixed Header / Fixed Columns
 */
