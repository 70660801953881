.progress-bar {
  width: 120px;
  height: 20px;
}
.progress-bar > span:nth-of-type(1) {
  background-color: #fcd55f;
}
.progress-bar > span > span:nth-of-type(1) {
  background-color: #f7961d;
}
.progress-bar > span > span:nth-of-type(2) {
  background-color: #f7961d;
}
.progress-bar > div:nth-of-type(1) {
  background-color: #fcd55f;
}
.progress-bar > div > div:nth-of-type(1) {
  background-color: #f7961d;
}
.progress-bar > div > div:nth-of-type(2) {
  background-color: #f7961d;
}
