.beam-navigation {
  position: relative;
  margin-top: 30px;
  min-height: 700px;
  height: 90vh;
  display: block;
}

.beam-navigation--beam-logo-container {
  margin: 25px 0 0;
  display: flex;
  align-items: center;
  height: 50px;
}

.beam-navigation--beam-logo {
  width: 62px;
  height: 32.94px;
  display: flex;
  align-items: center;
}

.beam-navigation--beam-logo > img {
  /* override for max-width default from tailwind */
  max-width: 62px;
}

.beam-navigation--partner-logo {
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding-left: 12px;
  max-width: 120px;
  max-height: 50px;
  min-height: 32.94px;
  border-left: 1px solid var(--beam-color--charcoal-100);
}

.beam-navigation--partner-logo > img {
  max-width: 100%;
  max-height: 50px;
  width: auto;
  height: auto;
}

.beam-navigation--links > a {
  width: 100%;
  display: flex;
  padding: 10px 4px;
  color: var(--beam-color--charcoal-500);
  text-decoration: none;
  border-radius: var(--beam--border-radius);
  margin-left: 16px;
}

.beam-navigation--user-badge-links > a {
  margin-left: 0;
}

span.beam-navigation--link {
  padding-left: 10px;
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-size: 0.875em;
  font-weight: 500;
  text-decoration: none;
}
.beam-navigation--links > a.active > svg path {
  fill: var(--beam-color--sky-500);
}
.beam-navigation--links > a.active > span {
  color: var(--beam-color--sky-500);
  font-weight: 900;
}

.beam-navigation--links > a:hover {
  /* background-color: var(--beam-color--coral-50); */
  color: var(--beam-color--sky-500);
  text-decoration: none;
}
.beam-navigation--links > a:hover > svg path {
  fill: var(--beam-color--sky-500);
}
.beam-navigation--links > a.active {
  /* background-color: var(--beam-color--coral-50); */
  text-decoration: none;
}

.beam-navigation--section-header,
.beam-navigation--user-badge--header {
  text-decoration: none;
  font-size: 0.75em;
  margin: 90px 0 10px 18px;
  font-weight: 400;
  color: var(--beam-color--charcoal-500);
}

.beam-navigation--user-badge {
  position: absolute;
  bottom: 50px;
}

sl-select.beam-navigation--site-drop-down::part(combobox) {
  background-color: var(--beam-color--sky-500);
}

sl-select.beam-navigation--site-drop-down::part(display-input) {
  color: var(--beam-color--charcoal-50);
  font-weight: lighter !important;
}
