.MuiDataGrid-root {
  border: none !important;
}
.invoice-status-select {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 600;
  background-color: none;
  color: #808191;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 7px 16px;
  box-sizing: border-box;
  cursor: pointer;
}
#selected-status {
  background-image: linear-gradient(270deg, #fbab7e, #f7ce68);
  color: #fff;
}
.filter-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.table-label {
  color: #11142d;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
}
.invoice-filter-dropdowns {
  display: flex;
}
.table-row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 0px;
  border-bottom: 1px solid rgb(228, 228, 228);
}
.invoice-link {
  font-size: 12px;
}
.invoice-description {
  line-height: 1.39;
  font-size: 13px;
  color: #b2b3bd;
  max-width: 80%;
}
.table-headers-container {
  display: flex;
  color: rgb(178, 179, 189);
  font-size: 13px;
  padding-left: 1%;
  padding-right: 1%;
  border-bottom: 1px solid rgb(228, 228, 228);
  margin-top: 30px;
  padding-bottom: 23px;
  font-weight: 500;
  box-sizing: border-box;
}
.expandable-row {
  height: 0px;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.5s;
  transition-delay: 0.08s;
}
.invoice-status-cell {
  width: 114px;
  color: #fff;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invoice-amount-cell {
  text-align: center;
  font-size: 14px;
}
