.invoice-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3em;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  border: '2px solid #000';
  padding: 0 2em 2em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-large-content {
  border: '2px solid #000';
  padding: 0 2em 2em 2em;
  display: flex;
  flex-direction: column;
}
