.CloseButton {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  opacity: 0.5;
}

.CloseButton:hover {
  opacity: 1;
}

.CloseButton:before,
.CloseButton:after {
  position: absolute;
  left: 9px;
  content: ' ';
  height: 20px;
  width: 2px;
}

.CloseButton:before {
  transform: rotate(45deg);
}

.CloseButton:after {
  transform: rotate(-45deg);
}
