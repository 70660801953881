.loadingIndicator {
  border-radius: var(--beam--border-radius);
  align-items: center;
  justify-content: center;
  display: flex;
}

.a11yLabel {
  visibility: hidden;
  height: 0;
  width: 0;
}

/* Thanks https://loading.io/css/ */
.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 100;
}
.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  transition: opacity, transform;
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsisStart 0.8s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsisMid 0.8s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsisMid 0.8s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsisMid 0.8s infinite;
}
.ldsEllipsis div:nth-child(5) {
  left: 80px;
  animation: lds-ellipsisEnd 0.8s infinite;
}
@keyframes lds-ellipsisStart {
  0% {
    transform: scale(0);
    opacity: 0.33;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes lds-ellipsisEnd {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0.33;
  }
}
@keyframes lds-ellipsisMid {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
