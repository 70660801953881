sl-button.beamTrueDropdownButton::part(base) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: var(--beam--font-weight-bold);
  white-space: nowrap;
}

/* Dropdown menu items */

/*.beamTrueDropdownMenuOption::part(base) {*/
sl-menu-item::part(base) {
  color: var(--beam-color--charcoal-600);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: var(--beam--font-weight-bold);
  font-size: var(--beam--font-size-small);
}

sl-menu-item::part(base):hover,
sl-menu-item::part(base):focus,
sl-menu-item::part(base):active {
  background-color: var(--beam-color--lime-100);
}
