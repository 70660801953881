.partnerUploadsTable th:first-child {
  width: 100%;
}

.partnerUploadsTable th:last-child {
  padding-right: 100px;
}

.tableButton::part(base) {
  white-space: nowrap;
  color: var(--beam-color--sky-500);
  font-weight: var(--beam--font-weight-normal);
  justify-content: left;
}

.tableButton::part(label) {
  padding: 0;
}
