.beamCheckbox::part(control) {
  background-color: var(--beam-color--white);
  border-color: var(--beam-color--charcoal-200);
  border-radius: var(--beam--border-radius-small);
}

.beamCheckbox::part(control--checked),
.beamCheckbox::part(control--indeterminate) {
  background-color: var(--beam-color--lime-500);
  border-color: var(--beam-color--charcoal-200);
  border-radius: var(--beam--border-radius-small);
}

.beamCheckbox::part(checked-icon),
.beamCheckbox::part(indeterminate-icon) {
  color: var(--beam-color--black);
}
