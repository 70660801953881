.archivedPartnerUploads th:first-child {
  width: 100%;
}

.archivedPartnerUploads .thead {
  @apply bg-charcoal-100;
}

.restoreButton::part(label) {
  padding: 0;
  white-space: nowrap;
  color: var(--beam-color--sky-500);
  font-weight: var(--beam--font-weight-normal);
}
