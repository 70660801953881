@media only screen and (max-width: 600px) {
  .beam-dropdown-placeholder,
  .beam-dropdown-item {
    padding: 5px 5px !important;
  }
  .beam-dropdown-container:nth-child(n + 1) {
    margin-top: 10px !important;
  }
}
.beam-dropdown-multi-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.beam-dropdown-multi-item {
  margin: 5px;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #e4e4e4;
}
.beam-dropdown-multi-item-close-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  background: transparent;
  border-color: transparent;
}
.beam-dropdown-item {
  background-color: rgb(240, 240, 240);
  box-sizing: border-box;
}
.beam-dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #808191;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #e4e4e4;
  height: fit-content;
}
.beam-dropdown-placeholder {
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  background-color: #e4e4e4;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  padding: 15px 10px;
  outline: none;
  border: none;
  padding: 18px 23px;
}
.beam-dropdown-item:hover {
  background-color: rgb(200, 200, 200);
}
input:focus {
  outline: none !important;
}
