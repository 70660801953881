:global(sl-switch.beam--toggle)::part(thumb) {
  background-color: var(--beam-color--charcoal-200);
  border: 0;
  --thumb-size: 18px;
}

:global(sl-switch[checked].beam--toggle)::part(thumb) {
  background-color: var(--beam-color--coral-500);
}

:global(sl-switch.beam--toggle)::part(label) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

:global(sl-switch.beam--toggle)::part(control) {
  background-color: var(--beam-color--sky-50);
  border: 0;
  --height: 18px;
}
