.passwordResetPage {
  /* This is done to override the default layout in the router. */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.passwordResetForm {
  margin: 1rem;
  background-color: var(--beam-color--white);
  border-radius: 8px;
}

.passwordResetForm img {
  display: inline; /* Override from index.css */
}
